import { Grid, Box, Typography, Container, Button } from "@mui/material/";
import Dollar from "../../assets/images/Dollar.png";
import Pricing from "../../assets/images/Pricing.png";
import Profit from "../../assets/images/Profit.png";
import People from "../../assets/images/People.png";
import Iteration from "../../assets/images/Iteration.png";
import BuyAWU from "../sections/BuyAwu";
import TokenICO from "../sections/TokenICO";
import BannerHeader from "../../layouts/global/BannerHeader";
import aboutUs from "../../assets/images/ico.png";
import "./ICO.css";

export default function ICO() {
  const data = [
    {
      className: "ico-figure",
      src: Dollar,
      content: "7,770,000,000 units",
      subContent: "Quantity",
      md: 4,
    },
    {
      className: "ico-figure",
      src: Pricing,
      content: "$0.1",
      subContent: "Issue Price",
      md: 4,
    },
    {
      className: "ico-figure",
      src: Profit,
      content: "777,000,000 units",
      subContent: "Market Capitalization",
      md: 4,
    },
    {
      className: "ico-figure",
      src: People,
      content: "BINANCE",
      subContent: "Network",
      md: 4,
    },
    {
      className: "ico-figure",
      src: Iteration,
      content: "POS",
      subContent: "Issuance Method",
      md: 4,
    },
  ];

  return (
    <div>
      <BannerHeader
        title="Initial Coin Offering"
        subTitle="Always with us"
        url={aboutUs}
      />
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Grid container spacing={5}>
          <Grid item lg={12} md={12} order={{ xs: 0, sm: 0, md: 1 }}>
            <p className="preTitle-issuance">ISSUANCE</p>
            <Typography
              component="h1"
              variant="h2"
              className="ico-title"
              gutterBottom
            >
              Overview of the <br />
              Issuance of HOMS Tokens
            </Typography>

            <Typography variant="h5" component="p" className="ico-description">
              The AWU token's economics are intended to offer tenants and the{" "}
              <br />
              Foundation a valuable asset. It will serve as the currency for all{" "}
              <br />
              transactions within the AWU ecosystem, allowing tenants to pay{" "}
              <br />
              their rent using HOMS tokens.
            </Typography>

            <Grid container className="ico-container-figure">
              {data?.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={item.md}
                  className="ico-container-per-figure"
                >
                  <Box component="figure" className={item.className}>
                    <img src={item.src} alt={item.content} />
                    <Typography
                      variant="caption"
                      component="figcaption"
                      className="ico-figcaption"
                    >
                      {item.content}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="figcaption"
                      className="ico-subfigcaption"
                    >
                      {item.subContent}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <TokenICO />
        <BuyAWU />
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#e99c31",
            color: "black",
            display: "block",
            margin: "20px auto",
            padding: "10px 20px",
            border: "none",
            borderRadius: "4px",
            textAlign: "center",
            textTransform: "uppercase",
            fontWeight: "bold",
            width: "auto",
            fontSize: "20px",
            "&:hover": {
              backgroundColor: "#633b05",
              color: "white",
            },
          }}
          href="https://homsworld.org/#/"
        >
          Buy HOMS Tokens
        </Button>
      </Container>
    </div>
  );
}
