import React, { useState } from "react";
import logo from "../../assets/images/AWU.png";
import AppBar from "@mui/material/AppBar";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const links = [
  { to: "/", label: "Home" },
  { to: "/About", label: "About Us" },
  { to: "/Board", label: "Board Directors" },
  { to: "/Members", label: "Members" },
  { to: "/Token", label: "HOMS Token" },
  { to: "/NFT", label: "AWU NFT" },
  { to: "/Cycle", label: "Our Cycle" },
  { to: "/News", label: "News" },
  { to: "/Events", label: "Events" },
  { to: "/ICO", label: "ICO" },
];

export default function Header() {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:820px)");

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <AppBar
      position="fixed"
      color="default"
      elevation={0}
      sx={{
        borderBottom: (theme) => `5px solid #0F1435`,
        backgroundColor: "#fff",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <a href="/">
              <img src={logo} alt="logo" style={{ maxWidth: "100px" }} />
            </a>
          </div>

          <span className="logo-text">ALWAYS WITH US</span>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {isMobile && (
            <IconButton
              edge="end"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}

          {!isMobile && (
            <nav>
              {links.map((link) => (
                <Link
                  key={link.to}
                  component={RouterLink}
                  to={link.to}
                  variant="button"
                  color="text.primary"
                  sx={{
                    mx: 1.5,
                    textDecoration: "none",
                    fontWeight: "bold",
                    "&:hover": {
                      color: "blue",
                    },
                  }}
                >
                  {link.label}
                </Link>
              ))}
            </nav>
          )}

          <Drawer
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <Box
              sx={{
                p: 2,
                height: "100%",
                backgroundColor: "#0e1328",
                color: "#fff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <IconButton
                  sx={{ mb: 2 }}
                  onClick={toggleDrawer(false)}
                  style={{ alignSelf: "flex-end" }}
                >
                  <CloseIcon sx={{ fill: "#fff" }} />{" "}
                  {/* Update close button color */}
                </IconButton>
                <nav className="navbar-mobile">
                  {" "}
                  {/* Update class */}
                  {links.map((link) => (
                    <Link
                      key={link.to}
                      component={RouterLink}
                      to={link.to}
                      variant="button"
                      color="text.primary"
                      sx={{
                        my: 1,
                        mx: 1.5,
                        textDecoration: "none",
                        fontWeight: "bold",
                        "&:hover": {
                          color: "#757575",
                        },
                      }}
                    >
                      {link.label}
                    </Link>
                  ))}
                </nav>
              </div>
              <div style={{ alignSelf: "center" }}></div>
            </Box>
          </Drawer>
        </div>
      </Toolbar>
    </AppBar>
  );
}
