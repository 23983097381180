import React from "react";
import BannerHeader from "../../layouts/global/BannerHeader";
import board from "../../assets/images/news.png";
import news1 from "../../assets/images/news1.png";
import news2 from "../../assets/images/news2.jpg";
import news3 from "../../assets/images/news3.jpg";
import news4 from "../../assets/images/news4.jpg";
import news5 from "../../assets/images/news5.jpg";
import news6 from "../../assets/images/NEWS6.webp";
import news7 from "../../assets/images/news7.jpg";
import news8 from "../../assets/images/news8.jpg";
import news9 from "../../assets/images/news9.png";
import news10 from "../../assets/images/news10.jpg";
import news12 from "../../assets/images/news12.png";
import news13 from "../../assets/images/news13.jpg";
import news14 from "../../assets/images/news14.jpeg";
import news15 from "../../assets/images/news15.jpg";
import news16 from "../../assets/images/news16.jpg";
import news17 from "../../assets/images/news17.jpg";
import news19 from "../../assets/images/news19.png";
import "./News.css";

const newsData = [
  {
    avatar: "CM",
    title: "AWU looking for PH partners",
    subheader: "June 16, 2023",
    image: news1,
    content:
      "International foundation Always With Us (AWU) said it is looking for more local partners to push its affordable housing advocacy for marginalized communities. Founded in Singapore this year, AWU has forged partnerships with like-minded...",
    link: "https://www.manilatimes.net/2023/06/16/news/national/awu-looking-for-ph-partners/1896290",
  },
  {
    avatar: "CM",
    title: "AWU Foundation Founding...",
    subheader: "June 16, 2023",
    image: news2,
    content:
      "AWU Foundation recently held an event “AWU FD Founding Ceremony”, a 1-day event last June 15th, 2023 in Dust Thani Manila. The purpose of the event was to launch the AWU foundation and the HOMS token.",
    link: "https://cornermagazineph.com/2023/06/20/awu-foundation-founding-ceremony/",
  },
  {
    avatar: "AB",
    title: "AWU Foundation Founding...",
    subheader: "June 20, 2023",
    image: news4,
    content:
      "AWU Foundation recently held an event “AWU FD Founding Ceremony”, a 1-day event last June 15th, 2023 in Dust Thani Manila. The purpose of the event was to launch the AWU foundation and the HOMS token.",
    link: "https://makingtrendz.com/business-and-entertainment/06/20/2023/awu-foundation-launched-in-the-philippines/",
  },
  {
    avatar: "MT",
    title: "MakingTrendz AWU",
    subheader: "June 20, 2023",
    image: news14,
    content:
      "The foundation believes that through collective efforts, we can make a significant difference in the lives of the people and contribute to building a better future. At the ceremony, speakers shared the vision and mission of the...",
    link: "https://makingtrendz.com/business-and-entertainment/06/20/2023/awu-foundation-launched-in-the-philippines/",
  },
  {
    avatar: "MB",
    title: "MB AWU founding ceremony",
    subheader: "June 22, 2023",
    image: news13,
    content:
      "International foundation Always With Us (AWU) recently held an event called the AWU FD Founding Ceremony, a one-day event last June 15, 2023 that was held in Dust Thani Manila. The purpose of the event..",
    link: "https://mb.com.ph/2023/6/23/awu-founding-ceremony",
  },
  {
    avatar: "NB",
    title: "NewsBytes PH...",
    subheader: "June 27, 2023",
    image: news15,
    content:
      "Crypto project launched to solve homelessness in PH, SE Asia. The Always With Us (AWU) Foundation has launched the BLX-backed HOMS token to address homelessness in the Philippines and Southeast Asia...",
    link: "https://newsbytes.ph/2023/06/27/crypto-project-launched-to-solve-homelessness-in-ph-se-asia/",
  },
  // {
  //   avatar: "BP",
  //   title: "BitPinas AWU Foundation...",
  //   subheader: "June 29, 2023",
  //   image: news10,
  //   content:
  //     "Foundation to Introduce New Token to Build Houses for Homeless Filipinos. To address homelessness in the Philippines, the Always With Us (AWU) Foundation partnered with crypto exchange Coexstar and...",
  //   link: "https://bitpinas.com/business/blox-awu-homs-crypto-build-houses/",
  // },
  {
    avatar: "AP",
    title: "Asia Property Awards",
    subheader: "August 29, 2023",
    image: news17,
    content:
      "The Always With Us (AWU) Foundation has introduced the BLX-backed HOMS token to tackle homelessness in the Philippines and Southeast Asia, as reported by ICT News source Newsbytes.PH. With a mission to ...",
    link: "https://www.asiapropertyawards.com/en/multiple-philippine-initiatives-address-affordable-housing-challenges/",
  },
  {
    avatar: "AP",
    title: "Asia Property Awards",
    subheader: "December 12, 2023",
    image: news16,
    content:
      "In a related development, News Bytes PH discussed how the Always With Us (AWU) Foundation introduced the HOMS token to address homelessness in the Philippines and Southeast Asia. Launched through cryptocurrency, the AWU Foundation plans to build 1,000 homes...",
    link: "https://www.asiapropertyawards.com/en/philippine-housing-initiatives-bridge-gaps-and-build-resilient-communities/ ",
  },
  {
    avatar: "SM",
    title: "SMNI featured AWU",
    subheader: "December 17, 2023",
    image: news5,
    content:
      "A day filled with joy and surprise unfolded in Taguig City on Friday morning as the Always With Us (AWU) Foundation, in collaboration with the Taguig City police, distributed Christmas grocery packs to over a thousand families from twenty-eight barangays in Taguig.",
    link: "https://smninewschannel.com/korean-foundation-provides-christmas-grocery-packs-to-1000-families-in-taguig-city/",
  },
  {
    avatar: "SM",
    title: "SMNI YT featured AWU",
    subheader: "December 17, 2023",
    image: news9,
    content:
      "1,000 pamilya sa Taguig, binigyan ng pang-Noche Buena ng isang Korean.For the upcoming year, the AWU Foundation said it will continue helping less fortunate Filipino families.They will concentrate on constructing 1,000 condo units that will be rented out at a...",
    link: "https://www.youtube.com/watch?v=Q5uHWT_sMws",
  },
  {
    avatar: "BM",
    title: "Business Mirror AWU",
    subheader: "December 21, 2023",
    image: news7,
    content:
      "The AWU Foundation aims to bring happiness and hope by creating secure and stable homes for less fortunate people. The foundation also seeks to prevent various crimes related to livelihood and promote the growth of the middle class, which is crucial...",
    link: "https://businessmirror.com.ph/2023/12/21/awu-foundation-year-end-and-christmas-donation/",
  },
  {
    avatar: "AB",
    title: "Shangabao AWU...",
    subheader: "December 25, 2023",
    image: news12,
    content: "As the year draws to a close, the foundation also reflects..",
    link: "https://www.shangbao.com.ph/shms/2023/12-25/151187.shtml",
  },
  {
    avatar: "AB",
    title: "Tempo Desk AWU...",
    subheader: "December 27, 2023",
    image: news8,
    content: "In the true spirit of giving, AWU Foundation held the “PNP..",
    link: "https://tempo.com.ph/2023/12/27/awu-foundation-holds-christmas-donation-grants-scholarships/",
  },
  {
    avatar: "GP",
    title: "Gempak AWU",
    subheader: "December 27, 2023",
    image: news5,
    content: "AWU Foundation's year-end and Christmas donation event...",
    link: "https://gempakmedia.com/story/awu-foundations-yearend-and-christmas-donation-event/r17519248",
  },
  {
    avatar: "KF",
    title: "Korean Foundation AWU...",
    subheader: "December 28, 2023",
    image: news6,
    content: "The Always With Us (AWU) Foundation, a Korean charity,..",
    link: "https://www.taguig.com/news/korean-foundation-awu-distributes-christmas/",
  },
  {
    avatar: "SM",
    title: "SMNI NEWS AWU...",
    subheader: "March 2024",
    image: news19,
    content: "The Always With Us (AWU) Foundation, a Korean charity,..",
    link: "https://rumble.com/v4j9t60-silang-cavite-napiling-benepisyaryo-ng-awu-foundation.html",
  },
];

const groupedNews = newsData.reduce((acc, news) => {
  const date = new Date(news.subheader);
  const monthYear = date.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  if (!acc[monthYear]) {
    acc[monthYear] = [];
  }
  acc[monthYear].push(news);
  return acc;
}, {});

export default function News() {
  return (
    <div>
      <BannerHeader title="News" subTitle="Always with us" url={board} />
      <div className="horizontal-news-container">
        {Object.entries(groupedNews).map(([monthYear, newsList]) => (
          <div key={monthYear} className="month-news">
            <h2>{monthYear}</h2>
            <div className="news-list">
              {newsList.map((news, index) => (
                <CustomCard key={index} {...news} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const CustomCard = ({ avatar, title, subheader, image, content, link }) => (
  <div className="custom-card">
    <div className="card-content">
      <div className="avatar-container">
        <div className="avatar">{avatar}</div>
        <div>
          <div className="title">{title}</div>
          <div className="subheader">{subheader}</div>
        </div>
      </div>
      <div className="image-container-news">
        <img src={image} alt="" />
      </div>
      <div className="content">{content}</div>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="read-more"
      >
        Read More
      </a>
    </div>
  </div>
);
