import {
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import FooterBGImage from "../../assets/images/footer_bg.png";
import AWUIcon from "../../assets/images/AWU.png";
import { Link as RouterLink } from "react-router-dom";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

const links = [
  { to: "/", label: "Home" },
  { to: "/About", label: "About Us" },
  { to: "/Board", label: "Board Directors" },
  { to: "/Members", label: "Members" },
  { to: "/Token", label: "HOMS Token" },
  { to: "/NFT", label: "AWU NFT" },
  { to: "/Cycle", label: "Our Cycle" },
  { to: "/ICO", label: "ICO" },
];

export default function Footer(props) {
  return (
    <Box {...props}>
      <Box
        className="footer"
        sx={{
          backgroundImage: `url('${FooterBGImage}')`,
          backgroundColor: "#EB9D33",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingTop: 15,
          position: "relative",
          paddingBottom: 5,
        }}
      >
        <svg
          className="footer-top-wave"
          viewBox="0 0 1440 180"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0,18L48,27C96,36,192,54,288,75C384,96,480,120,576,117C672,114,768,84,864,87C960,90,1056,126,1152,129C1248,132,1344,102,1440,84C1536,66,1632,60,1728,51C1824,42,1920,30,2016,48C2112,66,2208,114,2304,123C2400,132,2496,102,2592,102C2688,102,2784,132,2880,138C2976,144,3072,126,3168,123C3264,120,3360,132,3456,135C3552,138,3648,132,3744,135C3840,138,3936,150,4032,153C4128,156,4224,150,4320,141C4416,132,4512,120,4608,114C4704,108,4800,108,4896,105C4992,102,5088,96,5184,78C5280,60,5376,30,5472,15C5568,0,5664,0,5760,27C5856,54,5952,108,6048,123C6144,138,6240,114,6336,108C6432,102,6528,114,6624,99C6720,84,6816,42,6864,21L6912,0L6912,180L6864,180C6816,180,6720,180,6624,180C6528,180,6432,180,6336,180C6240,180,6144,180,6048,180C5952,180,5856,180,5760,180C5664,180,5568,180,5472,180C5376,180,5280,180,5184,180C5088,180,4992,180,4896,180C4800,180,4704,180,4608,180C4512,180,4416,180,4320,180C4224,180,4128,180,4032,180C3936,180,3840,180,3744,180C3648,180,3552,180,3456,180C3360,180,3264,180,3168,180C3072,180,2976,180,2880,180C2784,180,2688,180,2592,180C2496,180,2400,180,2304,180C2208,180,2112,180,2016,180C1920,180,1824,180,1728,180C1632,180,1536,180,1440,180C1344,180,1248,180,1152,180C1056,180,960,180,864,180C768,180,672,180,576,180C480,180,384,180,288,180C192,180,96,180,48,180L0,180Z"></path>
        </svg>

        <Container className="footer-content-container">
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box>
                <img src={AWUIcon} alt="" />
              </Box>

              <Grid
                container
                sx={{
                  marginLeft: 2,
                }}
              >
                <Typography className="footer-content-container">
                  {[
                    {
                      href: "mailto:awufd@homsworld.org",
                      icon: <EmailIcon />,
                    },
                    {
                      href: "https://www.facebook.com/awufoundation",
                      icon: <FacebookIcon />,
                    },
                    {
                      href: "https://t.me/awufd",
                      icon: <TelegramIcon />,
                    },
                    {
                      href: "https://www.youtube.com/@AWUFoundation",
                      icon: <YouTubeIcon />,
                    },
                    {
                      href: "https://www.instagram.com/awu.foundation/",
                      icon: <InstagramIcon />,
                    },
                    {
                      href: "https://www.tiktok.com/@awufoundation",
                      icon: <MusicNoteIcon />,
                    },
                  ].map((item, index) => (
                    <Link
                      key={index}
                      href={item.href}
                      target="_blank"
                      underline="hover"
                      className="footer-links"
                      sx={{
                        marginLeft: index > 0 ? 1 : 0,
                      }}
                    >
                      {item.icon}
                    </Link>
                  ))}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography className="footer_heading">Address</Typography>
              <Typography className="footer_text" sx={{ marginBottom: 5 }}>
                4f Unit B Commerce and Industry Plaza, 1030 Campus Avenue corner
                Park Avenue, McKinley Hill, Taguig City, Philippines
              </Typography>
              <Typography variant="h4" className="footer_heading">
                Email
              </Typography>

              <Typography>
                <Link
                  href="mailto:awufd@homsworld.org"
                  underline="hover"
                  className="footer_text"
                >
                  awufd@homsworld.org
                </Link>
              </Typography>
              <Typography>&nbsp;</Typography>
              <Typography variant="h4" className="footer_heading">
                HOMS Website
              </Typography>

              <Typography>
                <Link
                  href="https://homsworld.org/#/"
                  underline="hover"
                  className="footer_text"
                >
                  homsworld.org
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant="h4" className="footer_heading">
                Navigation
              </Typography>

              <List>
                {links.map((link) => (
                  <ListItem component={RouterLink} key={link.to} to={link.to}>
                    <Typography className="footer_link">
                      {link.label}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>

          <Grid container sx={{ marginTop: 5 }}>
            <Grid item xs={12} md={6}>
              <Typography className="footer_text">
                Copyright © 2023 AWU Foundation. All Rights Reserved.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                style={{
                  columnGap: "1rem",
                }}
              >
                <Grid
                  item
                  sx={{
                    marginLeft: {
                      xs: "unset",
                      lg: "auto !important",
                    },
                  }}
                >
                  <Typography className="footer_text">
                    <Link
                      component={RouterLink}
                      to="/TermsAndConditions"
                      underline="hover"
                      className="footer_text"
                    >
                      Terms & Conditions
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="footer-links-divider footer_text">
                    |
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <Link
                      component={RouterLink}
                      to="/PrivacyPolicy"
                      underline="hover"
                      className="footer_text"
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
