import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AWULayout from "./layouts/global/AWULayout";
import Homepage from "./views/HomeView/Homepage/Homepage";
import About from "./views/HomeView/About";
import Token from "./views/HomeView/Token";
import NFT from "./views/HomeView/NFT";
import Cycle from "./views/HomeView/Cycle";
import ICO from "./views/HomeView/ICO";
import "./assets/css/main.css";
import "./assets/js/reveal.js";
import Board from "./views/HomeView/Board";
import Members from "./views/HomeView/Members.js";
import TermsAndConditions from "./views/HomeView/TNC";
import PrivacyPolicy from "./views/HomeView/Policy";
import FAQs from "./views/HomeView/FAQs";
import BuyAWU from "./views/sections/BuyAwu";
import TokenICO from "./views/sections/TokenICO";
import News from "./views/HomeView/News";
import Events from "./views/HomeView/Events";

function App() {
  return (
    <BrowserRouter>
      <AWULayout>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/About" element={<About />} />
          <Route path="/Board" element={<Board />} />
          <Route path="/Members" element={<Members />} />
          <Route path="/Token" element={<Token />} />
          <Route path="/NFT" element={<NFT />} />
          <Route path="/Cycle" element={<Cycle />} />
          <Route path="/ICO" element={<ICO />} />
          <Route path="/News" element={<News />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/FAQs" element={<FAQs />} />
          <Route path="/BuyAWU" element={<BuyAWU />} />
          <Route path="/TokenICO" element={<TokenICO />} />
        </Routes>
      </AWULayout>
    </BrowserRouter>
  );
}

export default App;
