import { Box, Container, ListItem, Typography } from "@mui/material";
import React from "react";
import { Grid } from "@mui/material";
import Fundraising from "../../assets/images/fundraising.jpg";
import Expenses from "../../assets/images/expenses.jpg";
import Apartment from "../../assets/images/apartment.jpg";
import Income from "../../assets/images/income.jpg";
import Sale from "../../assets/images/sale.jpg";
import Reinvestment from "../../assets/images/reinvestment.jpg";
import Demand from "../../assets/images/demand.jpg";

export default function Future() {
  const projects = [
    {
      className: "project-figure",
      src: Fundraising,
      content: "Fundraising",
      subContent:
        "The AWU Foundation raises approximately $50,000,000 through an ICO or IEO.",
      md: 3,
    },
    {
      className: "project-figure",
      src: Expenses,
      content: "Initial Expenses",
      subContent:
        "$30,000,000 of the funds raised are used as primary project expenses to provide the first 1,000 apartments.",
      md: 3,
    },
    {
      className: "project-figure",
      src: Income,
      content: "Rental Income Tokenization",
      subContent:
        "Tokens secured by the rental income are converted into cash and injected into the second business, enabling a continuous supply of rental apartments.",
      md: 3,
    },
    {
      className: "project-figure",
      src: Apartment,
      content: "Rental Apartment Supply",
      subContent:
        "After six months of supplying rental apartments, a low-interest loan is obtained as collateral for the first apartment.",
      md: 3,
    },
    {
      className: "project-figure",
      src: Sale,
      content: "Sale of Rental Apartment",
      subContent:
        "After ten years, rental apartments can be sold, and existing tenants are given priority in the sales target list.",
      md: 3,
    },
    {
      className: "project-figure",
      src: Reinvestment,
      content: "Continuous building cycle",
      subContent:
        "The income generated from the sales will be used to create new rental apartments, ensuring its continuous building cycle.",
      md: 3,
    },
    {
      className: "project-figure",
      src: Demand,
      content: "Token Demand and Value Increase",
      subContent:
        "As the supply of rental apartments increases, the demand for tokens will increase accordingly, leading to a gradual rise in their value.",
      md: 3,
    },
  ];

  return (
    <div>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Grid container spacing={5}>
          <Grid item lg={12} md={12} order={{ xs: 0, sm: 0, md: 1 }}>
            <div className="reveal">
              <p className="preTitle-issuance">AWU Foundation</p>
              <Typography
                component="h1"
                variant="h2"
                className="ico-title"
                gutterBottom
              >
                Overview of the <br />
                Foundation's Projects
              </Typography>

              <Typography
                variant="h5"
                component="p"
                className="ico-description "
              >
                AWU Foundation project aims to address the housing problem for
                the poor in ASEAN <br />
                countries by providing affordable rental apartments and creating
                a stable and happy community.
              </Typography>
            </div>

            <Grid container className="project-container-figure">
              {projects?.map((item) => (
                <Grid
                  item
                  xs={item.xs}
                  xsOffset={item.xsOffset}
                  md={item.md}
                  mdOffset={item.mdOffset}
                  className="project-container-per-figure "
                >
                  <Box>
                    <img
                      src={item.src}
                      alt={item.content}
                      className={item.className}
                    />
                    <ListItem className="project-figcaption">
                      {item.content}
                    </ListItem>
                    <ListItem className="project-subfigcaption">
                      {item.subContent}
                    </ListItem>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
